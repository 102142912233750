import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import Layout from "../components/layout"
import GlobalStyles from "../Global"
import ContactUs from "../components/contact/ContactUs"

function contact() {
  return (
    <Layout>
      <GlobalStyles />
      <SEO title="Contact Us" />
      <ContactUs />
    </Layout>
  )
}

export default contact

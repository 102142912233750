import React, { useState } from "react"
import { PrimaryTitle } from "../../elements/Titles"
import styled from "styled-components"
import { PrimaryButton } from "../../elements/Buttons"

const StyledContactSection = styled.section`
  .hidden{
    display: none;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
    color: #2b3646;
  }

  a:visited {
    color: #8c9bac;
  }
`

const Paragraph = styled.p`
  font-size: 1.3rem;
  text-align: center;
  width: 60%;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  button {
    align-self: center;
  }

  label {
  }

  > div {
    margin: 0.7rem 0;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    border: 2px solid #d1d4d6;
    height: 35px;
    margin: 0.25rem 0;
    padding: 1rem;
    margin-left: 2rem;
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
  }
`

const StyledTextarea = styled.div`
  display: flex;

  textarea {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #d1d4d6;
    padding: 1rem;
    font-family: "Open Sans", sans-serif;
    font-size: 0.85rem;
    margin-left: 0.8rem;
  }
`

function ContactUs() {
  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  })

  const updateField = e => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <StyledContactSection className="wrapper">
      <PrimaryTitle>Contact Us</PrimaryTitle>

      <Paragraph>
        Have questions ? Lets Talk. <br />
        Call us or email us, we would love to hear from you.
      </Paragraph>

      <StyledForm
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <input type="text" name="subject" value="Form submission from CAH contact form" class="hidden"/>


        <div>
          <label for="name">Name:</label>
          <input
            id="name"
            type="text"
            placeholder="Name"
            name="name"
            value={value.name}
            onChange={updateField}
            required
          />
        </div>
        <div>
          <label for="email">E-mail:</label>
          <input
            id="email"
            type="email"
            placeholder="Email"
            name="email"
            onChange={updateField}
            required
          />
        </div>
        <div>
          <label for="tel">Phone:</label>
          <input
            id="tel"
            type="tel"
            placeholder="phone number"
            autocomplete="tel"
            name="phone"
            onChange={updateField}
          />
        </div>
        <StyledTextarea>
          <label for="message">Message:</label>
          <textarea
            name="message"
            placeholder="Your message"
            id="message"
            cols="48"
            rows="5"
            onChange={updateField}
            required
          ></textarea>
        </StyledTextarea>

        <PrimaryButton type="submit">Submit</PrimaryButton>
      </StyledForm>
      <h2>
        <a href="tel:+15752013210" target="_blank" rel="noopener noreferrer">
          575-201-3210
        </a>
      </h2>
      <h3>info@customaltohomes.com</h3>
    </StyledContactSection>
  )
}

export default ContactUs
